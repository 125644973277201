import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    portrait:sessionStorage.getItem("PORTRAIT"),
    userLoginName:sessionStorage.getItem("USER_LOGIN_NAME"),
    orderFlag:'',
    headerPic:'',
  },
  getters: {
  },
  mutations: {
    changePortrait(state,n){
      state.portrait = n;
      sessionStorage.setItem("PORTRAIT",n)
    },
    changeUserLoginName(state,n){
      state.userLoginName = n;
      sessionStorage.setItem("USER_LOGIN_NAME",n)
    },
    changeOrderFlag(state,n){
      state.orderFlag = n;
    },
    changeHeadpic(state,n){
      state.headerPic = n;
    },
  },
  actions: {},
  modules: {}
})
