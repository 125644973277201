<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  mounted() {},
}
</script>
<style scoped lang="scss">
@import './assets/style/index.css';
@import 'assets/style/color-dark.css';

/deep/ .el-input-number__decrease,
/deep/ .el-input-number__increase {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
/deep/ .el-input-number__increase .el-icon-arrow-up {
  display: none;
}
/deep/ .el-input-number__decrease .el-icon-arrow-down {
  display: none;
}
/deep/ .el-input-number__decrease:before {
  content: '-' !important;
}

/deep/ .el-input-number__increase:before {
  content: '+' !important;
}

/* 滚动条样式 */
div::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

div::-webkit-scrollbar-track {
  background: #e5e6eb;
  border-radius: 2px;
}
div::-webkit-scrollbar-thumb {
  background: #babec7;
  border-radius: 10px;
}

div::-webkit-scrollbar-thumb:hover {
  background: #babec7;
}

div::-webkit-scrollbar-corner {
  background: #babec7;
}
</style>
