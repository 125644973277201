import axios from 'axios';
import baseurl from './baseurl';
import ElementUI from 'element-ui';
import { Loading, Message } from 'element-ui'
import Vue from "vue";
import router from "@/router";
const service = axios.create({
    baseURL: baseurl.baseURL,
    timeout: 500000,
});

Vue.use(ElementUI);
// 添加请求拦截器,
service.interceptors.request.use(request => {
    var tokenUser = localStorage.getItem('token')
    // 设置请求的头信息
    request.headers = {
      'X-Token': tokenUser,
    }
    return request
  },
  error => {
    Promise.reject(error)
  }
)

// 添加响应拦截器
service.interceptors.response.use(response => {
    //未登录
    if(response.data.attributes.code == 50018){
      //Vue.prototype.$message.error(response.data.attributes.msg)
      router.push('/login')
    }
    return response
  },
  error => {
    Promise.reject(error)
  }
)

export default service;
