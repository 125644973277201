import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
Vue.use(ElementUI);
Vue.use(VXETable)

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

Vue.config.productionTip = false

//公共地址
import baseAPI from './utils/baseurl'
// import TIM from "tim-js-sdk";
Vue.prototype.API = baseAPI.baseURL


const whiteList = ['/forgetPass','/elawAid']  //白名单

//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  const role = localStorage.getItem('token');
  if(whiteList.indexOf(to.path) != -1){
    next()
    return
  }
  if (!role && to.path !== '/login') {
    next('/login');
  } else {
    next();
  }
});
// let options = {
//   SDKAppID: 1400816569 // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
// };
// // 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
// let tim = TIM.create(options); // SDK 实例通常用 tim 表示
// tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用 1
// tim.on(TIM.EVENT.KICKED_OUT, () => {
//   console.log('KICKED_OUT')
//   localStorage.removeItem('token')
//   localStorage.removeItem('userId')
//   localStorage.removeItem('name')
//   router.push('/login')
// });
//
// Vue.prototype.tim = tim
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
