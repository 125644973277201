import Vue from 'vue';
import Router from 'vue-router';
import {getLawyerStatus} from "@/api/layer_page/layerPage";

Vue.use(Router);

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const routes =  [
  {
    path: '/',
    redirect: '/login'
    // redirect: '/elawAid'
  },
  {
    path: '/',
    component: () => import('../components/Home'),
    meta: {title: '自述文件'},
    children: [
      {
        path: '/dashboard',
        component: () => import('../views/dashboard/Dashboard'),
        meta: {title: '系统首页'}
      },
      {
        path: '/lawyerIndex',
        component: () => import('../views/dashboard/lawyerIndex'),
        meta: {title: '系统首页'}
      },
      {
        path: '/agentIndex',
        component: () => import('../views/dashboard/agentIndex'),
        meta: {title: '系统首页'}
      },
      //资讯管理
      {
        path: '/infoManage',
        component: () => import('../views/info_page/infoManage.vue'),
        meta: {title: '资讯列表'}
      },
      {
        path: '/addInfo',
        component: () => import('../views/info_page/addInfo.vue'),
        meta: {title: '资讯管理'}
      },
      //诉讼类文书管理
      {
        path: '/caseType',
        component: () => import('../views/caseClerk/caseType.vue'),
        meta: {title: '诉讼文书类型管理'}
      },
      {
        path: '/courseType',
        component: () => import('../views/caseClerk/courseType.vue'),
        meta: {title: '程序类型管理'}
      },
      {
        path: '/caseClerkManage',
        component: () => import('../views/caseClerk/caseClerkManage.vue'),
        meta: {title: '诉讼文书管理'}
      },
      {
        path: '/addCaseClerk',
        component: () => import('../views/caseClerk/addCaseClerk.vue'),
        meta: {title: '诉讼文书管理'}
      },
      //合同管理
      {
        path: '/industryType',
        component: () => import('../views/contract_page/industryType.vue'),
        meta: {title: '行业类型管理'}
      },
      {
        path: '/contractType',
        component: () => import('../views/contract_page/contractType.vue'),
        meta: {title: '合同类型管理'}
      },
      {
        path: '/contractDocument',
        component: () => import('../views/contract_page/contractDocument.vue'),
        meta: {title: '合同文档管理'}
      },
      {
        path: '/addContract',
        component: () => import('../views/contract_page/addContract.vue'),
        meta: {title: '新增合同'}
      },
      //律师信息管理
      {
        path: '/lawyermsgList',
        component: () => import('../views/lawyermsg_page/lawyermsgList.vue'),
        meta: {title: '律师管理'}
      },
      {
        path: '/addLawyermsg',
        component: () => import('../views/lawyermsg_page/addLawyermsg.vue'),
        meta: {title: '律师管理详情'}
      },

      //公益视频管理
      {
        path: '/videoSort',
        component: () => import('../views/video_page/videoSort.vue'),
        meta: {title: '视频分类管理'}
      },
      {
        path: '/videoManager',
        component: () => import('../views/video_page/videoManager.vue'),
        meta: {title: '视频管理'}
      },
      {
        path: '/addVideo',
        component: () => import('../views/video_page/addVideo.vue'),
        meta: {title: '发布视频'}
      },
      //订单管理
      {
        path: '/orderManager',
        component: () => import('../views/order_page/orderManager.vue'),
        meta: {title: '订单查询'}
      },
      {
        path: '/orderDetail',
        component: () => import('../views/order_page/orderDetail.vue'),
        meta: {title: '订单详情'}
      },
      //高级管理
      {
        path: '/adverManager',
        component: () => import('../views/advanced_page/adverManager.vue'),
        meta: {title: '宣传图列表'}
      },
      {
        path: '/leaveCheck',
        component: () => import('../views/advanced_page/leaveCheck.vue'),
        meta: {title: '留言列表'}
      },
      {
        path: '/workNeed',
        component: () => import('../views/advanced_page/workNeed.vue'),
        meta: {title: '合作需求'}
      },
      {
        path: '/workNeedDetail',
        component: () => import('../views/advanced_page/workNeedDetail.vue'),
        meta: {title: '合作需求详情'}
      },
      {
        path: '/leaveDetail',
        component: () => import('../views/advanced_page/leaveDetail.vue'),
        meta: {title: '留言详情'}
      },
      {
        path: '/addLeave',
        component: () => import('../views/advanced_page/addLeave.vue'),
        meta: {title: '宣传图管理'}
      },
      {
        path: '/adModify',
        component: () => import('../views/advanced_page/adModify.vue'),
        meta: {title: '宣传图编辑'}
      },
      //客户管理
      {
        path: '/businessAllot',
        component: () => import('../views/client_page/businessAllot.vue'),
        meta: {title: '商机分配'}
      },
      {
        path: '/qualiAudit',
        component: () => import('../views/client_page/qualiAudit.vue'),
        meta: {title: '资格审核'}
      },
      {
        path: '/clientEdit',
        component: () => import('../views/client_page/clientEdit.vue'),
        meta: {title: '编辑客户'}
      },
      {
        path: '/clientCheck',
        component: () => import('../views/client_page/clientCheck.vue'),
        meta: {title: '查看客户'}
      },
      {
        path: '/commercialManager',
        component: () => import('../views/client_page/commercialManager.vue'),
        meta: {title: '商机管理'}
      },
      {
        path: '/myClient',
        component: () => import('../views/client_page/myClient.vue'),
        meta: {title: '我的客户'}
      },
      //律师端管理
      // {
      //   path: '/OnlineStatus',
      //   name:'OnlineStatus',
      //   // component: () => import('../views/lawyer_page/onLineanswerdoubt/onlineStart.vue'),
      //   meta: {title: '线上答疑'}
      // },
      {
        path: '/onlineStart',
        name:'OnlineStart',
        component: () => import('../views/lawyer_page/onLineanswerdoubt/onlineStart.vue'),
        meta: {title: '线上答疑'}
      },
      {
        path: '/answerdoubtHistoryList',
        component: () => import('../views/lawyer_page/answerdoubtHistory/answerdoubtHistoryList.vue'),
        meta: {title: '答疑历史记录'}
      },
      {
        path: '/answerdoubtList',
        component: () => import('../views/lawyer_page/answerdoubtTpye/answerdoubtList.vue'),
        meta: {title: '答疑类型设置'}
      },{
        path: '/onlineChat',
        name: 'OnlineChat',
        component: () => import('../views/lawyer_page/tencentConsult/index.vue'),
        meta: {title: '在线答疑'}
      },
      {
        path: '/consultcardList',
        component: () => import('../views/lawyer_page/consultCardsetup/consultcardList.vue'),
        meta: {title: '咨询卡设置'}
      },
      {
        path: '/addConsult',
        component: () => import('../views/lawyer_page/consultCardsetup/addConsult.vue'),
        meta: {title: '新增咨询卡'}
      },
      {
        path: '/lawsletterList',
        component: () => import('../views/lawyer_page/lawyerletterManage/lawsletterList.vue'),
        meta: {title: '律师函管理'}
      },
      {
        path: '/lawsletterDetils',
        component: () => import('../views/lawyer_page/lawyerletterManage/lawsletterDetils.vue'),
        meta: {title: '任务签收'}
      },
      {
        path: '/lawsuitWrit',
        component: () => import('../views/lawyer_page/lawsuitwritManage/lawsuitwritList.vue'),
        meta: {title: '诉讼文书管理'}
      },
      {
        path: '/writDetail',
        component: () => import('../views/lawyer_page/lawsuitwritManage/writDetail.vue'),
        meta: {title: '任务签收'}
      },
      {
        path: '/writType',
        component: () => import('../views/lawyer_page/lawsuitwritTypemanage/writtypeList.vue'),
        meta: {title: '合同定制管理'}
      },
      {
        path: '/addCagegory',
        component: () => import('../views/lawyer_page/lawsuitwritTypemanage/addCagegory.vue'),
        meta: {title: '合同定制管理'}
      },
      {
        path: '/contractCustom',
        component: () => import('../views/lawyer_page/contractCustom/contractCustom.vue'),
        meta: {title: '合同定制管理'}
      },
      {
        path: '/signFor',
        component: () => import('../views/lawyer_page/contractCustom/signFor.vue'),
        meta: {title: '任务签收'}
      },
      {
        path: '/lawsuitSave',
        component: () => import('../views/lawyer_page/lawsuitManage/lawsuitsaveList.vue'),
        meta: {title: '诉讼保全申请管理'}
      },
      {
        path: '/checkMess',
        component: () => import('../views/lawyer_page/lawsuitManage/checkMess.vue'),
        meta: {title: '信息查看'}
      },

      //系统设置
      {
        path: '/userManagement',
        component: () => import('../views/system_page/userManagement.vue'),
        meta: {title: '用户管理'}
      },
      {
        path: '/addUser',
        component: () => import('../views/system_page/addUser.vue'),
        meta: {title: '用户管理详情'}
      },
      {
        path: '/roleManagement',
        component: () => import('../views/system_page/roleManagement.vue'),
        meta: {title: '角色管理'}
      },
      {
        path: '/roleUser',
        component: () => import('../views/system_page/roleUser.vue'),
        meta: {title: '角色用户'}
      },
      {
        path: '/menuManagement',
        component: () => import('../views/system_page/menuManagement.vue'),
        meta: {title: '菜单管理'}
      },
      //个人信息
      {
        path: '/personMess',
        component: () => import('../views/userInfo/personMess.vue'),
        meta: {title: '个人资料'}
      },
      {
        path: '/passAmend',
        component: () => import('../views/userInfo/passAmend.vue'),
        meta: {title: '合作需求'}
      },
      //权限页面
      {
        path: '/permission',
        component: () => import('../views/permission/Permission.vue'),
        meta: {title: '权限测试', permission: false}
      },
      {
        path: '/404',
        component: () => import('../views/permission/404.vue'),
        meta: {title: '404'}
      },
      {
        path: '/403',
        component: () => import('../views/permission/403.vue'),
        meta: {title: '403'}
      },
    ]
  },
  {
    path: '/login',
    component: () => import('../views/login/login'),
    meta: {title: '登录'}
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/forgetPass',
    component: () => import('../views/userInfo/forgetPass'),
    meta: {title: '忘记密码'}
  },
  {
    path: '/elawAid',
    component: () => import('../views/index/elawAid'),
    meta: {title: 'e律帮'}
  },


  //tyc
  {
    path: '/companySearch',
    component: () => import('../views/tyc/companySearch'),
    meta: {title: '公司查询'}
  },
  {
    path: '/companySearchRes',
    component: () => import('../views/tyc/companySearchRes'),
    meta: {title: '公司查询结果'}
  },
  {
    path: '/companyDetail',
    component: () => import('../views/tyc/companyDetail'),
    meta: {title: '公司详情'}
  },
  {
    path: '/patentSearch',
    component: () => import('../views/tyc/patentSearch'),
    meta: {title: '专利查询'}
  },
  {
    path: '/patentSearchRes',
    component: () => import('../views/tyc/patentSearchRes'),
    meta: {title: '专利查询结果'}
  },
  {
    path: '/patentDetail',
    component: () => import('../views/tyc/patentDetail'),
    meta: {title: '专利详情'}
  },
  {
    path: '/trademarkSearch',
    component: () => import('../views/tyc/trademarkSearch'),
    meta: {title: '商标查询'}
  },
  {
    path: '/trademarkSearchRes',
    component: () => import('../views/tyc/trademarkSearchRes'),
    meta: {title: '商标查询结果'}
  },
  {
    path: '/trademarkDetail',
    component: () => import('../views/tyc/trademarkDetail'),
    meta: {title: '商标详情'}
  },
]

 const router = new Router({
  routes: routes
});

export default router



