import request from '../../utils/request';


//获取律师状态
export const getLawyerStatus = () => {
  return request({
    url: '/web/session/run/getLawyerStatus',
    method: 'get'
  });
};


//开始工作
export const beginWork = () => {
  return request({
    url: '/web/session/run/beginWork',
    method: 'post'
  });
};



